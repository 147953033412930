.field label {
    line-height: 14px;
    font-size: 12px;
}

.wrap {
    width: 100%;
    position: relative;
}

.inputWrap {
    position: relative;
}

.placeholder {
    position: absolute;
    top: 50%;
    left: 16px;
    color: #333333;
    font-size: 12px;
    opacity: .7;
    transform: translateY(-50%);
}

.black .placeholder {
    color: #ffffff;
}

.label {
    display: block;
    color: #444444;
    font-size: 12px;
    letter-spacing: 0.1px;
    font-weight: 700;
    margin-bottom: 5px;
}

.black .label {
    color: #ffffff;
    font-weight: 700;
}

.error {
    position: absolute;
    bottom: -17px;
    right: 0;
    font-size: 12px;
    color: #f31818;
}

.black .error {
    bottom: -1px;
}

.slide {
    position: absolute;
    bottom: 0;
    left: 5px;
    width: calc(100% - 10px);
    height: 1px;
    background: #ffffff;
}
