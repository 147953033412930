.field label {
    line-height: 14px;
    font-size: 12px;
}

.field label {
    line-height: 14px;
    font-size: 12px;
}

.wrap {
    width: 100%;
    position: relative;
}

.label {
    display: block;
    color: #444444;
    font-size: 12px;
    letter-spacing: 0.1px;
    font-weight: 700;
    margin-bottom: 5px;
}

.black .label {
    color: #ffffff;
    font-weight: 700;
}

.placeholder {
    position: absolute;
    top: 50%;
    left: 16px;
    color: #333333;
    font-size: 12px;
    opacity: .7;
    transform: translateY(-50%);
}

.black .placeholder {
    color: #ffffff;
}

.error {
    position: absolute;
    bottom: -17px;
    right: 0;
    font-size: 12px;
    color: #f31818;
}

.selectWrap {
    position: relative;
}

.clear {
    position: absolute;
    top: 50%;
    right: 5px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    transform: translateY(-50%);
}

.clear:hover svg {
    fill: #777777 !important;
}

.clear svg {
    width: 100%;
    height: 100%;
}

.isWow {
    border-radius: 5px;
    animation: wow 1s;
}

.poof {
    width: 64px;
    height: 64px;
    margin-left: -32px;
    background: url('./poof.png') 0 0/auto 320px;
    opacity: 0;
    z-index: 1;
    position: absolute;
    bottom: calc(100% - 17px);
    left: 50%;
    transform: rotateX(-50%);
    pointer-events: none;
}

@media (max-width: 520px) {
    .poof {
        transform: scale(0.7);
        bottom: calc(100% - 25px);
    }
}

.poofAction {
    background-position: 0 -320px;
    background-repeat: no-repeat;
    opacity: 0.5;
    z-index: 3;
    -o-transition: opacity .2s ease,background-position .3s steps(5) .1s;
    transition: opacity .2s ease,background-position .3s steps(5) .1s
}

@keyframes wow {
    0% {
        box-shadow: 0 0 35px 0 #0099ff;
    }
}
