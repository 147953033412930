
.completedScreenWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow: hidden;
  pointer-events: none;
}

.completedScreen {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: translateY(-100%);
  top: -1%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  z-index: 100;
  transition: .75s ease-in-out;
  pointer-events: visible;
}

.completedScreen.completed {
  transform: translateY(1%);
}

.completedScreenContent {
  position: relative;
  z-index: 100;
  padding: 30px;
  //width: 54%;
}

.completedScreenTitle {
  display: block;
  font-size: 30px;
  font-weight: 900;
  margin-bottom: 4px;
  color: #555555;
}

.completedScreenDesc {
  display: block;
  margin-bottom: 14px;
  color: #333333;
  max-width: 500px;
}
.completedScreenButton {
  margin-top: 0;
  max-width: 200px;
}

@media (max-width: 530px) {
  .completedScreenTitle {
    font-size: 24px;
  }
}
